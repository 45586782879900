import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Alert,
  AlertTitle,
  useMediaQuery,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconFile, IconRecycle, IconExternalLink } from "@tabler/icons-react";
import { useState } from "react";
import theme from "lib/theme";
import { formatDate } from "utils/helpers";
import { Pickup } from "types/pickup";
import { Link as RouterLink } from "react-router-dom";
import { PickupStatus } from "./PickupStatus";
import usePickups from "hooks/pickups/usePickups";

interface PickupTableProps {
  pickups: Pickup[];
  errorMessage: null;
  isLoading: boolean;
  getPickups: () => void;
  hasNextPage: boolean;
  deletePickup: (pickupUuid: string) => Promise<void>;
  incrementCurrentPage: () => void;
}

export default function PickupCards({
  pickups,
  errorMessage,
  isLoading,
  deletePickup,
  getPickups,
}: PickupTableProps) {
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  if (errorMessage) {
    return (
      <Box
        sx={{
          mt: 6,
          border: "1px solid",
          borderColor: theme.palette.divider,
          background: "white",
          minHeight: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage || "There was a problem loading your pickups."}
        </Alert>
      </Box>
    );
  }

  const isEmpty = pickups?.length === 0;

  if (!isLoading && isEmpty) {
    return (
      <Box
        sx={{
          mt: 6,
          p: 4,
          borderRadius: "12px",
          boxShadow: 2,
          background: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" fontWeight={"bold"} sx={{ mt: 2 }}>
          No pickups scheduled yet!
        </Typography>
        <Typography
          mt={1}
          maxWidth={"400px"}
          textAlign="center"
          sx={{ color: "#555" }}
        >
          Ready to start? Schedule your first pickup with the button below.
        </Typography>
        <Button
          component={RouterLink}
          variant="contained"
          size="large"
          to="/pickups/new"
          sx={{ mt: 4 }}
        >
          Schedule Pickup
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Grid container spacing={2}>
        {pickups.map((pickup) => (
          <Grid item xs={12} sm={6} md={4} key={pickup.id}>
            <PickupCard
              pickup={pickup}
              deletePickup={deletePickup}
              getPickups={getPickups}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function PickupCard({
  pickup,
  deletePickup,
  getPickups,
}: {
  pickup: Pickup;
  deletePickup: Function;
  getPickups: Function;
}) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { getCod, getRecyclingSummary } = usePickups();

  const handleDelete = async () => {
    await deletePickup(pickup.id);
    getPickups();
    setConfirmOpen(false);
  };

  const downloadCod = async () => {
    try {
      const codUrl = await getCod(pickup?.uuid || "");
      console.log({ codUrl });
      const response = await fetch(codUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "pickup_certificate_of_destruction.pdf";
      a.click();
    } catch (error) {
      console.error("Error downloading COD:", error);
    }
  };

  const downloadRecyclingSummary = async () => {
    try {
      const recyclingSummaryUrl = await getRecyclingSummary(pickup?.uuid || "");
      console.log({ recyclingSummaryUrl });
      const response = await fetch(recyclingSummaryUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "pickup_recycling_summary.pdf";
      a.click();
    } catch (error) {
      console.error("Error downloading Recycling Summary:", error);
    }
  };

  return (
    <Card sx={{ width: "100%", minHeight: "225px", position: "relative" }}>
      <CardContent>
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            display: "flex",
            gap: 1,
          }}
        >
          {pickup.state === "pending_confirmation" && (
            <>
              <IconButton
                size="small"
                component={RouterLink}
                to={`/pickups/${pickup.uuid}/edit`}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => setConfirmOpen(true)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </Box>
        <Typography variant="h6" component="div">
          {(pickup.scheduledDate && formatDate(pickup.scheduledDate)) ||
            "Select Date"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Pickup Number: {pickup.orderNumber}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Address:{" "}
          {(pickup.location && pickup.location.address) || "Add address"}{" "}
          {(pickup.location && pickup.location.address2) || ""} <br></br>
          {(pickup.location && pickup.location.city) || ""}
          {pickup.location && pickup.location.state
            ? `, ${pickup.location.state}`
            : ""}{" "}
          {pickup.location && pickup.location.zipcode
            ? ` ${pickup.location.zipcode}`
            : ""}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Point of Contact: {pickup.contactName || "Add contact"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Status: <PickupStatus pickup={pickup} />
        </Typography>
        {pickup.state === "complete" && (
          <Box sx={{ pt: 2 }}>
            <Typography
              variant="body2"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box mr={1}>
                <IconFile />
              </Box>
              <Link component="button" onClick={downloadCod}>
                Download COD
              </Link>
            </Typography>

            <Typography
              variant="body2"
              component="div"
              sx={{ display: "flex", alignItems: "center", mt: 1 }}
            >
              <Box mr={1}>
                <IconRecycle />
              </Box>
              <Link component="button" onClick={downloadRecyclingSummary}>
                Download Recycling Summary
              </Link>
            </Typography>

            <Typography
              variant="body2"
              component="div"
              sx={{ display: "flex", alignItems: "center", mt: 1 }}
            >
              <Box mr={1}>
                <IconExternalLink />
              </Box>
              <Link
                component={RouterLink}
                to={`/assets?filter[]=state,is,retired&filter[]=pickup_id,is_any_of,${pickup.id}`}
              >
                View all Assets
              </Link>
            </Typography>
          </Box>
        )}
      </CardContent>

      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Pickup"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this pickup?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
