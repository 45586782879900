import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { api } from "services/api.service";
import TokenService from "services/token.service";
import { useAlert } from "./alerts/useAlert";

type AuthenticationContextValue = {
  loggedIn: Boolean;
  setLoggedIn: (loggedIn: Boolean) => void;
};

export const AuthenticationContext = createContext<AuthenticationContextValue>({
  loggedIn: false,
  setLoggedIn: () => {},
});

export const AuthenticationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    Boolean(TokenService.get())
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { search } = useLocation();

  const value = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
    }),
    [loggedIn]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default function useAuthentication() {
  const { loggedIn, setLoggedIn } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const { alertInfo } = useAlert();
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const {
        data: { jwt },
      } = await api.post("/sessions", { email, password });

      TokenService.set(jwt);
      setLoggedIn(true);
    },
    [setLoggedIn]
  );

  return {
    login,
    loggedIn,
  };
}
