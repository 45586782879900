import PickupForm from "./PickupForm";
import { Box } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useLocations from "hooks/locations/useLocations";
import useCreatePickup from "hooks/pickups/useCreatePickup";
import { Pickup } from "types/pickup";

const NewPickup = () => {
  const { createPickup } = useCreatePickup();
  const { locations } = useLocations();

  const handleCreatePickup = (values: Pickup) => {
    createPickup(values);
  };

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[
            { label: "Home", to: "/" },
            { label: "Pickups", to: "/pickups" },
            { label: "New" },
          ]}
        ></Breadcrumbs>
      </Box>
      <PickupForm onSubmit={handleCreatePickup} locations={locations} />
    </div>
  );
};

export default NewPickup;
