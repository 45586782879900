import {
  TableContainer,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import theme from "lib/theme";
import { Menu } from "components/common/Menu";
import { MoreVert } from "@mui/icons-material";
import useUsers from "hooks/users/useUsers";
import { User } from "types/user";

function UserManagementTable() {
  const { users, deleteUser } = useUsers();
  return (
    <TableContainer
      sx={{
        mt: 6,
        border: "1px solid",
        borderColor: theme.palette.divider,
        background: "white",
        borderRadius: "4px",
      }}
      component={Box}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>First name</TableCell>
            <TableCell>Last name</TableCell>
            <TableCell>Email address</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => {
            return (
              <UserManagementTableRow
                key={user.uuid}
                user={user}
                deleteUser={deleteUser}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type UserProps = {
  user: User;
  deleteUser: (uuid: string) => any;
};

function UserManagementTableRow({ user, deleteUser }: UserProps) {
  return (
    <TableRow>
      <TableCell>{user.firstName}</TableCell>
      <TableCell>{user.lastName}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.phoneNumber}</TableCell>
      <TableCell align="right">
        <Menu
          button={(props: any) => (
            <span>
              <IconButton {...props}>
                <MoreVert />
              </IconButton>
            </span>
          )}
          actions={[
            {
              label: "Edit User",
              to: `${user.uuid}/edit`,
            },
            {
              label: "Delete User",
              onClick: () => {
                deleteUser(user.uuid);
              },
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
}

export default UserManagementTable;
