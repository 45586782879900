import { Search } from "@mui/icons-material";
import {
  Box,
  InputLabel as MuiInputLabel,
  Select,
  SelectProps,
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import theme from "lib/theme";
import React from "react";
import { OutlinedInput } from "@mui/material";

const TextField = styled(MuiTextField)({
  ".MuiOutlinedInput-root": { padding: "0px !important" },
  ".MuiInputBase-input": {
    borderColor: "rgb(206, 212, 218)",
    minHeight: "38px",
    padding: "0px 12px !important",
    background: "white",
  },
  ".MuiInputBase-input.MuiInputBase-inputMultiline": {
    padding: "12px 12px !important",
  },
  fieldset: {
    borderColor: "rgb(206, 212, 218)",
  },
});

export const InputBase = React.forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
  return <TextField autoComplete="off" ref={ref} fullWidth variant="outlined" {...props} />;
});

export const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  position: "static",
  color: `${theme.palette.text.primary} !important`,
  fontWeight: 600,
  fontSize: "16px",
  transform: "none",
  marginBottom: "4px",
}));

export function SearchInput({ sx, width = "415px", ...props }: TextFieldProps & { width?: string }) {
  return (
    <Box sx={{ position: "relative", width: "100%", maxWidth: width }}>
      <InputBase sx={[sx as any, { ".MuiInputBase-input": { pl: "40px !important" } }]} {...props} />
      <Search sx={{ position: "absolute", top: "7px", left: "8px", color: theme.palette.action.active }} />
    </Box>
  );
}

export function AppSelect<T>({ value, placeholder, ...props }: SelectProps<T>) {
  return (
    <Select
      displayEmpty
      sx={{
        ".MuiSelect-standard": { padding: "0px !important" },
        ".MuiSelect-select.MuiInputBase-input": {
          borderColor: "rgb(206, 212, 218)",
          height: "38px !important",
          display: "flex",
          alignItems: "center !important",
          padding: "0px 12px !important",
          ":focus": {
            borderRadius: "4px",
            backgroundColor: "white",
          },
        },
        ".MuiSelect-iconStandard": { right: "8px !important" },
        fieldset: {
          borderColor: "rgb(206, 212, 218)",
        },
      }}
      renderValue={value == null ? () => <Box sx={{ color: "#a2a2a2" }}>{placeholder}</Box> : undefined}
      input={<OutlinedInput sx={{}} />}
      value={value}
      {...props}
    ></Select>
  );
}

export function RequiredSymbol() {
  return <span style={{ color: theme.palette.error.main, fontWeight: 600 }}>*</span>;
}
