import { format } from "date-fns";

export function formatDate(dateString: string) {
  if (!dateString) return;
  var date = new Date(dateString.substring(0, 10)).toISOString().slice(0, -1);

  return format(new Date(date), "MMMM dd, yyyy"); // ex: April 27, 2022;
}

export function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumberToCurrency(number: number) {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}
