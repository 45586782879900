import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import TokenService from "services/token.service";
import { api } from "services/api.service";

function Support() {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.token) {
      api
        .post("/start_support_session", { support_token: params.token })
        .then(({ data: { jwt } }) => {
          TokenService.set(jwt);
          window.location.href = "/";
        });
    }
  }, [params, navigate]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress />
    </Box>
  );
}

export default Support;
