import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { api } from "services/api.service";
import { useAlert } from "hooks/alerts/useAlert";

function Login() {
  const { alertSuccess } = useAlert();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          padding: "50px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "400px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            mb: 3,
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Login
        </Typography>

        <Formik
          initialValues={{ email: "" }}
          onSubmit={async (values) => {
            if (values.email) {
              api.post("/generate_magic_link", { email: values.email });
              alertSuccess("A Link has been sent to your email");
            }
          }}
          validationSchema={yup.object({
            email: yup
              .string()
              .email("Invalid Email")
              .required("Email Required"),
          })}
        >
          {({ getFieldProps, errors, handleSubmit, touched }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <FormControl variant="standard" sx={{ mb: 2 }}>
                  <TextField
                    type="email"
                    variant="outlined"
                    label="Email"
                    {...getFieldProps("email")}
                    id="email"
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{
                      backgroundColor: "#f9f9f9",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#999",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#333",
                        },
                      },
                    }}
                  />
                </FormControl>

                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  sx={{
                    textTransform: "uppercase",
                    mt: 2,
                    alignSelf: "center",
                    backgroundColor: "#4caf50",
                    "&:hover": {
                      backgroundColor: "#388e3c",
                    },
                  }}
                >
                  Email me link
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default Login;
