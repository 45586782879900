/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useEffect, useState } from "react";
import theme from "lib/theme";
import { useSearchParams } from "react-router-dom";
import useSearchParamsOperations from "hooks/useSearchParamsOperations";
import usePickups from "hooks/pickups/usePickups";
import useAssets from "hooks/assets/useAssets";
import { SecurityLocks } from "./SecurityLocks";
import { AssetsGrid } from "./AssetsDataGrid";
import useInfiniteScroll from "react-infinite-scroll-hook";

function Assets() {
  const {
    assets,
    isLoading,
    isError,
    meta,
    refetch,
    setSelectedAssetIds,
    selectedAssets,
    selectedAssetIds,
    bulkUnenroll,
    setSelectAllFlag,
    selectAllFlag,
    getAllSerialsNumbers,
  } = useAssets();
  const [searchParams] = useSearchParams();
  const {
    errorMessage,
    isLoading: filterIsLoading,
    hasNextPage,
    incrementCurrentPage,
  } = usePickups(true);
  const {
    replace,
    deleteFieldInArrayUsingPrefix,
    replaceInArrayFieldUsingPrefix,
  } = useSearchParamsOperations();
  const [pickupIds, setPickupIds] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("pickup_id,is_any_of"))
      ?.split("pickup_id,is_any_of,")[1]
      ?.split(",") || []
  );
  const [locationId, setLocationId] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("location_id,is"))
      ?.split("location_id,is,")[1] || ""
  );
  const [status, setStatus] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("status,is"))
      ?.split("status,is,")[1] || ""
  );
  const [value, setValue] = useState("retired");
  const [securityLocksOpen, setSecurityLocksOpen] = useState(false);
  const [retiredAssetOpen, setRetiredAssetOpen] = useState(false);

  const [sentryRef] = useInfiniteScroll({
    loading: filterIsLoading,
    hasNextPage,
    onLoadMore: incrementCurrentPage,
    disabled: !!errorMessage,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    replaceInArrayFieldUsingPrefix(
      "filter[]",
      "state,is_any_of,",
      `state,is_any_of,${value}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (pickupIds && pickupIds.length > 0) {
      replaceInArrayFieldUsingPrefix(
        "filter[]",
        "pickup_id,is_any_of",
        `pickup_id,is_any_of,${pickupIds.join(",")}`
      );
      if (value !== "retired") setValue("retired");
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "pickup_id,is_any_of");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupIds]);

  useEffect(() => {
    if (locationId && locationId.length > 0) {
      replaceInArrayFieldUsingPrefix(
        "filter[]",
        "location_id,is",
        `location_id,is,${locationId}`
      );
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "location_id,is");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  useEffect(() => {
    if (status && status.length > 0) {
      replaceInArrayFieldUsingPrefix(
        "filter[]",
        "status,is",
        `status,is,${status}`
      );
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "status,is");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[{ label: "Home", to: "/" }, { label: "Assets" }]}
        ></Breadcrumbs>
      </Box>

      <Box
        sx={{
          mt: 2,
          padding: 2,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box sx={{ mt: 0, ".MuiTabPanel-root": { padding: 0 } }}>
          {selectedAssets && selectedAssets[0] && (
            <>
              <SecurityLocks
                open={securityLocksOpen}
                setOpen={setSecurityLocksOpen}
                bulkUnenroll={bulkUnenroll}
              />
            </>
          )}
          <AssetsGrid
            fieldsToHide={["assignedTo"]}
            replace={replace}
            assets={assets}
            isLoading={isLoading}
            isError={isError}
            meta={meta}
            refetch={refetch}
            setSelectedAssetIds={setSelectedAssetIds}
            selectedAssetIds={selectedAssetIds}
            setSelectAllFlag={setSelectAllFlag}
            selectAllFlag={selectAllFlag}
            assetsTab={value}
            setRetiredAssetOpen={setRetiredAssetOpen}
            setSecurityLocksOpen={setSecurityLocksOpen}
            getAllSerialsNumbers={getAllSerialsNumbers}
          />
        </Box>
      </Box>
    </div>
  );
}

export default Assets;
