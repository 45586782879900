export default function subdomain(): string | null {
  const host = window.location.host;

  const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
  if (arr.length > 0) {
    return host.includes("vercel.app")
      ? localStorage.getItem("subdomain") || "recycle"
      : arr[0];
  }
  return null;
}
