import { createContext, useContext, useEffect, useState } from "react";

type GlobalContextType = {
  loading: boolean;
  apiCalls: number;
  setDirty: any;
  setApiCalls: any;
};

export const GlobalContext = createContext<GlobalContextType>({
  loading: true,
  apiCalls: 0,
  setDirty: () => {},
  setApiCalls: () => {},
});

export const GlobalLoaderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [apiCalls, setApiCalls] = useState(0);

  useEffect(() => {
    // if (!dirty) return;
    // setLoading(apiCalls > 0);
    if (apiCalls === 0) {
      setLoading(false);
    }
  }, [apiCalls, dirty]);

  return (
    <GlobalContext.Provider
      value={{ loading, apiCalls, setApiCalls, setDirty }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalLoader = () => {
  const { setApiCalls, loading, setDirty } = useContext(GlobalContext);

  const fireApiCall = (apiCall: Promise<any>) => {
    setApiCalls((currentApiCalls: number) => currentApiCalls + 1);
    apiCall.finally(() => {
      setDirty(true);
      setApiCalls((currentApiCalls: number) => currentApiCalls - 1);
    });
  };

  return {
    fireApiCall,
    loading,
  };
};

export default {};
