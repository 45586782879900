import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";

export default function useUpdatePickup() {
  const params = useParams();
  const navigate = useNavigate();

  const updatePickup = useCallback(
    (pickupData: Pickup) =>
      api
        .put<Pickup>(`/pickups/${params.pickupUuid}`, {
          pickup: pickupData,
        })
        .then((res: any) => {
          navigate(`/pickups`);
        }),
    [params.pickupUuid, navigate]
  );

  return {
    updatePickup,
  };
}
