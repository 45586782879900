import { Box } from "@mui/material";
import { Pickup } from "types/pickup";

export function PickupStatus({ pickup }: { pickup: Pickup }) {
  const stateLabel: any = {
    missing_info: "Missing Info",
    pending_confirmation: "Pending Confirmation",
    scheduled: "Scheduled",
    canceled: "Canceled",
    processing: "Processing",
    on_hold: "On Hold",
    complete: "Complete",
  };

  const getStatusStyles = (state: string) => {
    switch (state) {
      case "missing_info":
        return {
          backgroundColor: "#FFCDD2",
          color: "#B71C1C",
        };
      case "pending_confirmation":
        return {
          backgroundColor: "#E0E0E0",
          color: "#616161",
        };
      case "scheduled":
        return {
          backgroundColor: "#B3E5FC",
          color: "#01579B",
        };
      case "canceled":
        return {
          backgroundColor: "#757575",
          color: "#FFFFFF",
        };
      case "processing":
        return {
          backgroundColor: "#FFE0B2",
          color: "#EF6C00",
        };
      case "on_hold":
        return {
          backgroundColor: "#FFF176",
          color: "#F57F17",
        };
      case "complete":
        return {
          backgroundColor: "#C8E6C9",
          color: "#388E3C",
        };
      default:
        return {
          backgroundColor: "#E0E0E0",
          color: "#616161",
        };
    }
  };

  return (
    <Box
      component="span"
      sx={{
        display: "inline-block",
        padding: "2px 8px",
        borderRadius: "4px",
        fontWeight: "bold",
        ...getStatusStyles(pickup.state || "pending_confirmation"),
      }}
    >
      {stateLabel[pickup.state || "pending_confirmation"]}
    </Box>
  );
}
