import { useCallback } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";

export default function usePickup() {
  const params = useParams();

  const { data, isLoading, isError, refetch } = useQuery(`pickup/${params.pickupUuid}`, () =>
    api.get<Pickup>(`/pickups/${params.pickupUuid}`).then((res) => ({ data: res.data }))
  );

  const createImage = useCallback(
    (imageKey: string) => {
      api.post<Pickup>(`/pickups/${params.pickupUuid}/pickup_images`, { imageKey }).then(() => {
        refetch();
      });
    },
    [refetch, params.pickupUuid]
  );

  const deleteImage = useCallback(
    (attachmentId: number) => {
      api.delete<Pickup>(`/pickups/${params.pickupUuid}/pickup_images?attachment_id=${attachmentId}`).then(() => {
        refetch();
      });
    },
    [refetch, params.pickupUuid]
  );

  return {
    pickup: data?.data,
    isLoading,
    isError,
    createImage,
    deleteImage,
  };
}
