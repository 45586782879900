import "./assets/index.css";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { Chart, registerables } from "chart.js";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import theme from "./lib/theme";
import { AuthenticationProvider } from "hooks/useAuthentication";
import { AlertProvider } from "hooks/alerts/useAlert";
import { CurrentUserProvider } from "hooks/useCurrentUser";
import { LicenseInfo } from "@mui/x-data-grid-pro";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { GlobalLoaderProvider } from "hooks/useGlobalLoader";
import { ConfirmationProvider } from "hooks/useConfirmation";

const licenseKey = process.env.REACT_APP_MUI_PRO || "";
LicenseInfo.setLicenseKey(licenseKey);

Chart.register(...registerables);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const dsn = process.env.REACT_APP_SENTRY_DSN;
if (dsn) {
  const rate = parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || "1.0");
  Sentry.init({
    dsn,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "production",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: rate,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <GlobalLoaderProvider>
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <AlertProvider>
            <ConfirmationProvider>
              <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <AuthenticationProvider>
                    <CurrentUserProvider>
                      <App />
                    </CurrentUserProvider>
                  </AuthenticationProvider>
                </BrowserRouter>
              </QueryClientProvider>
            </ConfirmationProvider>
          </AlertProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </GlobalLoaderProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
