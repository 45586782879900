import PickupForm from "./PickupForm";
import usePickup from "hooks/pickups/usePickup";
import { Pickup } from "types/pickup";
import useUpdatePickup from "hooks/pickups/useUpdatePickup";
import Breadcrumbs from "components/common/Breadcrumbs";
import { Box } from "@mui/material";
import useLocations from "hooks/locations/useLocations";

const EditPickup = () => {
  const { pickup } = usePickup();
  const { updatePickup } = useUpdatePickup();
  const { locations } = useLocations();

  const handleUpdatePickup = (values: Pickup) => {
    updatePickup(values);
  };

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[
            { label: "Home", to: "/" },
            { label: "Pickups", to: "/pickups" },
            { label: "Edit" },
          ]}
        ></Breadcrumbs>
      </Box>
      <PickupForm
        pickup={pickup}
        onSubmit={handleUpdatePickup}
        locations={locations}
      />
    </div>
  );
};

export default EditPickup;
