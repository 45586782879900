import Pages from "pages";
import { useGlobalLoader } from "hooks/useGlobalLoader";
import { Box, CircularProgress } from "@mui/material";

export default function App() {
  const { loading } = useGlobalLoader();
  return loading ? (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress sx={{ marginTop: 3 }} />
    </Box>
  ) : (
    <Pages />
  );
}
