/* istanbul ignore file */

import Cookies from "js-cookie";

const TokenService = {
  set: function (token: string) {
    const expires = { expires: 90 };
    Cookies.set("jwtToken", token, expires);
  },

  remove: function () {
    Cookies.remove("jwtToken");
  },

  get: function (): string | undefined {
    return Cookies.get("jwtToken");
  },
};

export default TokenService;
