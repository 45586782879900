import { createTheme } from "@mui/material";

export default createTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#353637",
    },
    secondary: {
      main: "#81C41D",
      contrastText: "#FFFFFF",
    },
    neutral: {
      main: "#7A7A7A",
      light: "#323232",
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    neutral: true;
  }
}
