import Nav from "components/AppLayout/Nav";
import {
  Box,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItemButton as MuiListItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
  Toolbar,
  styled,
  ListItemTextProps,
  Container,
  ListItemButtonProps,
  useMediaQuery,
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useState, useEffect } from "react";
import useAuth from "hooks/useAuthentication";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "lib/theme";
import {
  IconTruckDelivery,
  IconDeviceLaptop,
  IconSettings,
} from "@tabler/icons-react";

const ListItem = styled(MuiListItem)<ListItemButtonProps>(({ theme }) => ({
  "&.Mui-selected": { borderRight: `4px solid ${theme.palette.primary.main}` },
})) as typeof MuiListItem;

const ListItemText = styled(MuiListItemText)<ListItemTextProps>(
  ({ theme }) => ({
    ".MuiTypography-root": { fontWeight: "bold" },
  })
);

const drawerWidth = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    top: "66px",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    }),
    [theme.breakpoints.down("lg")]: {
      top: `0 !important`,
      ...(!open && { width: 0 }),
    },
  },
}));

interface ListItemsProps {
  handleToggle(): void;
}

export function ListItems({ handleToggle }: ListItemsProps) {
  const { pathname } = useLocation();
  const isTabletView = useMediaQuery(theme.breakpoints.down("lg"));

  function handleItemClick() {
    if (isTabletView) {
      handleToggle();
    }
  }

  return (
    <List>
      {" "}
      {/* <ListItem
        onClick={handleItemClick}
        selected={pathname === "/"}
        component={Link}
        to="/"
      >
        {" "}
        <ListItemIcon>
          {" "}
          <IconHome color={theme.palette.primary.main} />{" "}
        </ListItemIcon>{" "}
        <ListItemText primary="Home" />
      </ListItem> */}
      <ListItem
        onClick={handleItemClick}
        selected={pathname === "/" || pathname.includes("/pickups")}
        component={Link}
        to="/pickups"
      >
        <ListItemIcon>
          <IconTruckDelivery color={theme.palette.primary.main} />
        </ListItemIcon>
        <ListItemText primary="Pickups" />
      </ListItem>
      <ListItem
        onClick={handleItemClick}
        selected={pathname.includes("/assets")}
        component={Link}
        to="/assets"
      >
        <ListItemIcon>
          <IconDeviceLaptop color={theme.palette.primary.main} />
        </ListItemIcon>
        <ListItemText primary="Assets" />
      </ListItem>
      <ListItem
        onClick={handleItemClick}
        selected={pathname.startsWith("/company-settings")}
        component={Link}
        to="/company-settings"
      >
        <ListItemIcon>
          <IconSettings color={theme.palette.primary.main} />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </List>
  );
}

interface AppLayoutProps {
  children: React.ReactNode;
  noPadding?: boolean;
  container?: boolean;
}

function AppLayout({
  children,
  noPadding = false,
  container = false,
}: AppLayoutProps) {
  const { loggedIn } = useAuth();
  const isTabletView = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = useState(!isTabletView);

  function handleToggle() {
    setOpen((open) => !open);
  }

  useEffect(() => {
    if (
      !loggedIn &&
      !window.location.href.includes("login") &&
      !window.location.href.includes("support")
    ) {
      window.location.href = "/login";
    }
    setOpen(!isTabletView);
  }, [isTabletView, setOpen, loggedIn]);

  return (
    <div>
      <Nav open={open} handleToggle={handleToggle} />

      {loggedIn ? (
        <>
          <Drawer
            data-testid="app-drawer"
            variant="permanent"
            open={open}
            aria-expanded={open}
          >
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton
                color="primary"
                onClick={handleToggle}
                aria-label={open ? "Collapse Sidebar" : "Expand Sidebar"}
              >
                {open ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
            </Toolbar>

            <ListItems handleToggle={handleToggle}></ListItems>
          </Drawer>

          <Box
            sx={{
              padding: noPadding ? "none" : 6,
              minHeight: "calc(100vh - 64px)",
              marginLeft: open ? `${drawerWidth}px` : 7,
              marginTop: "64px",
              [theme.breakpoints.down("lg")]: {
                marginLeft: 0,
                padding: noPadding ? "none" : 3,
              },
              [theme.breakpoints.down("sm")]: {
                padding: noPadding ? "none" : 2,
                minHeight: "calc(100vh - 58px)",
                marginTop: "58px",
              },
            }}
          >
            {container ? (
              <Container sx={{ [theme.breakpoints.down("md")]: { px: 0 } }}>
                {children}
              </Container>
            ) : (
              children
            )}
          </Box>
        </>
      ) : (
        <>
          <Container>{children}</Container>
        </>
      )}
    </div>
  );
}

export default AppLayout;
