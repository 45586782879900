/* istanbul ignore file */

import axios, { CancelTokenSource } from "axios";
import { objectToCamel, objectToSnake } from "ts-case-convert";
import subdomain from "lib/subdomain";
import TokenService from "./token.service";
import _ from "lodash";
import { PickupContact } from "types/pickupContact";

export const HOSTNAME = process.env.REACT_APP_API_BASE_URL || "";

export const api = axios.create({
  baseURL: `${HOSTNAME}api/v1`,
  withCredentials: true,
});

const requestCancelSourcesByKey: { [cancelKey: string]: CancelTokenSource } =
  {};

export const cancelRequest = (cancelKey: string) => {
  const cancelSource = requestCancelSourcesByKey[cancelKey];
  if (cancelSource) {
    cancelSource.cancel();
    delete requestCancelSourcesByKey[cancelKey];
  }
};

export const emptyStringsToNull = (data: PickupContact[]) => {
  return data.map((contact: PickupContact) => {
    return _.mapValues(contact, (value) => (value === "" ? null : value));
  });
};

api.interceptors.request.use(
  (config: any) => {
    if (config.cancelKey) {
      cancelRequest(config.cancelKey);
      const newCancelSource = axios.CancelToken.source();
      requestCancelSourcesByKey[config.cancelKey] = newCancelSource;
      config.cancelToken = newCancelSource.token;
    }
    if (config.data) {
      config.data = objectToSnake(config.data);
      if (config.data.location) {
        if (config.data.location.address_2) {
          config.data.location["address2"] = config.data.location.address_2;
          delete config.data.location["address_2"];
        }
        if (config.data.location.location_contacts) {
          config.data.location.location_contacts = emptyStringsToNull(
            config.data.location.location_contacts
          );
        }
      }
    }
    if (TokenService.get())
      config.headers.Authorization = `Bearer ${TokenService.get()}`;

    const organizationSubdomain = subdomain();
    if (organizationSubdomain) {
      config.headers.common["organization-subdomain"] = subdomain();
    }
    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return { ...response, data: objectToCamel(response.data) };
  },
  function (error) {
    if (error?.code === "ERR_NETWORK") {
      // TokenService.remove();
      if (!window.location.href.includes("no-backend-connection"))
        window.location.href = "/no-backend-connection";
      return Promise.reject(error);
    }

    if (
      error.response?.status === 401 &&
      !window.location.href.includes("login")
    ) {
      // TokenService.remove();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);
