/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from "@mui/material";
import UserManagementTable from "./UserManagementTable";
import { Link as RouterLink } from "react-router-dom";

function UserManagement() {
  return (
    <div>
      <Box
        mt={4}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>
          <Button
            component={RouterLink}
            variant="contained"
            to={"/company-settings/users/new"}
          >
            invite new user
          </Button>
        </span>
      </Box>
      <Box mt={4}>
        <UserManagementTable />
      </Box>
    </div>
  );
}

export default UserManagement;
