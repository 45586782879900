import { Box } from "@mui/material";
import theme from "lib/theme";
import Breadcrumbs from "components/common/Breadcrumbs";
import CompanySettingNavbar from "./CompanySettingNavbar";
import { Outlet, useLocation } from "react-router-dom";

function CompanySettings() {
  let location = useLocation();
  let [, tabValue] = location.pathname.split("/").filter(Boolean);

  return (
    <div>
      <Box
        sx={{
          background: "white",
          borderBottom: `1px solid ${theme.palette.divider}`,
          p: 6,
          pb: 4,
        }}
      >
        <Breadcrumbs
          data={[
            { label: "Home", to: "/" },
            { label: "Settings", to: `/company-settings/${tabValue}` },
            {
              label: {
                users: "Users",
              }[tabValue],
            },
          ]}
        />

        <Box mt={3}>
          <CompanySettingNavbar />
        </Box>
      </Box>
      <Box sx={{ p: 6, pt: 4 }}>
        <Outlet />
      </Box>
    </div>
  );
}

export default CompanySettings;
