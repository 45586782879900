/* eslint-disable no-self-compare */
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Alert,
  AlertTitle,
  Skeleton,
  Stack,
  LinearProgress,
  Typography,
  Divider,
} from "@mui/material";
import theme from "lib/theme";
import { Asset } from "types/asset";
import {
  DataGridPro,
  GridColDef,
  GridHeaderSelectionCheckboxParams,
  GridRenderCellParams,
  GridRowId,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import AssetsExportGridItem from "./AssetsExportGridItem";
import SelectAllAssetsBanner from "./SelectAllAssetsBanner";
import { formatDate } from "utils/helpers";
import { PickupStatus } from "pages/Pickups/PickupStatus";

type AssetGridProps = {
  replace: (field: string, value: string) => void;
  assets: Asset[];
  isLoading: boolean;
  isError: boolean;
  meta: {
    count: number;
    totalPages: number;
  };
  refetch: () => void;
  setSelectedAssetIds: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  selectedAssetIds: GridSelectionModel;
  setSelectAllFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  selectAllFlag?: boolean;
  assetsTab: string;
  setSecurityLocksOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRetiredAssetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getAllSerialsNumbers: (
    asset_ids?: GridRowId[],
    selectAllFlag?: boolean
  ) => Promise<void>;
  fieldsToHide: Array<string>;
};

type ToolbarProps = {
  count: number;
};

const CustomToolbar = ({ count }: ToolbarProps) => {
  return (
    <>
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <AssetsExportGridItem />
        </Box>

        <Typography
          mr={1}
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
        >
          {count} Assets Found
        </Typography>
      </GridToolbarContainer>
      <Divider />
    </>
  );
};

const assetStatusStyle = (status: string) => {
  if (status == "Available") {
    return { borderRadius: "100%", background: theme.palette.success.light };
  } else if (status == "Enrolled") {
    return { borderRadius: "100%", background: theme.palette.error.light };
  } else if (status == "Checked Out") {
    return { borderRadius: "100%", background: theme.palette.primary.light };
  } else if (status == "Pending Pickup") {
    return { borderRadius: "100%", background: theme.palette.secondary.main };
  } else if (status == "Verifying Release") {
    return { borderRadius: "100%", background: theme.palette.warning.main };
  } else if (status == "Retired") {
    return { borderRadius: "100%", background: "#bcc2be" };
  } else if (status == "Repurposed") {
    return { borderRadius: "100%", background: theme.palette.primary.dark };
  } else if (status == "Recycled") {
    return { borderRadius: "100%", background: "#bcc2be" };
  } else {
    return { borderRadius: "100%", background: theme.palette.warning.light };
  }
};

export function AssetsGrid({
  replace,
  assets,
  setSelectedAssetIds,
  selectedAssetIds,
  isLoading,
  isError,
  meta,
  refetch,
  setSelectAllFlag,
  selectAllFlag,
  assetsTab,
  setSecurityLocksOpen,
  setRetiredAssetOpen,
  getAllSerialsNumbers,
  fieldsToHide,
}: AssetGridProps) {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const apiRef = useGridApiRef();

  const handleSelectAllChange = useCallback(
    (selectedAll: boolean) => {
      if (!selectedAll && selectAllFlag) setSelectAllFlag?.(false);
      setHeaderSelectAll(selectedAll);
    },
    [selectAllFlag, pageNumber, apiRef]
  );

  useEffect(() => {
    if (meta.totalPages > 1) {
      return apiRef.current.subscribeEvent(
        "headerSelectionCheckboxChange",
        (a: GridHeaderSelectionCheckboxParams, event) => {
          event.defaultMuiPrevented = true;
          handleSelectAllChange(a.value);
        }
      );
    }
  }, [meta.totalPages, apiRef]);

  useEffect(() => {
    setHeaderSelectAll(false);
  }, [assets]);

  useEffect(() => {
    replace("page[number]", (pageNumber + 1).toString());
  }, [pageNumber, replace]);

  useEffect(() => {
    replace("page[size]", pageSize.toString());
  }, [pageSize, replace]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "status",
        headerName: "STATUS",
        width: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const asset = params.row;

          return (
            <Box display="flex" alignItems="center" gap={1}>
              {asset.status || "Unknown"}
              <Box
                flexShrink={0}
                width={8}
                height={8}
                sx={assetStatusStyle(asset.status)}
              ></Box>
            </Box>
          );
        },
      },
      {
        field: "orderNumber",
        headerName: "PICKUP",
        width: 125,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup?.orderNumber}
            </Box>
          );
        },
      },
      {
        field: "scheduledDate",
        headerName: "RETIRED DATE",
        width: 175,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {formatDate(params.row.pickup?.scheduledDate)}
            </Box>
          );
        },
      },
      {
        field: "state",
        headerName: "PICKUP STATUS",
        width: 175,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup && <PickupStatus pickup={params.row.pickup} />}
            </Box>
          );
        },
      },
      {
        field: "address",
        headerName: "ADDRESS",
        width: 175,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.location?.address}
            </Box>
          );
        },
      },
      {
        field: "deviceType",
        headerName: "TYPE",
        sortable: false,
        width: 175,
      },
      {
        field: "manufacturer",
        headerName: "MANUFACTURER",
        sortable: false,
        width: 175,
      },
      {
        field: "serialNumber",
        headerName: "SERIAL",
        sortable: false,
        width: 175,
      },
      {
        field: "model",
        headerName: "MODEL",
        sortable: false,
        width: 175,
      },
      {
        field: "assetTag",
        headerName: "ASSET TAG",
        sortable: false,
        width: 175,
      },
      {
        field: "quantity",
        headerName: "QUANTITY",
        sortable: false,
        width: 100,
      },
    ],
    [selectedAssetIds, getAllSerialsNumbers]
  );

  if (isError) {
    return (
      <Box
        sx={{
          border: "1px solid",
          borderColor: theme.palette.divider,
          background: "white",
          minHeight: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Something went wrong
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      {headerSelectAll ? (
        <SelectAllAssetsBanner
          selectedAssetIds={selectedAssetIds}
          setSelectedAssetIds={setSelectedAssetIds}
          setSelectAllFlag={setSelectAllFlag}
          selectAllFlag={selectAllFlag}
          setHeaderSelectAll={setHeaderSelectAll}
          meta={meta}
        />
      ) : null}
      <DataGridPro
        sx={{ height: "80vh" }}
        apiRef={apiRef}
        paginationMode="server"
        pagination
        rows={assets}
        columns={columns}
        disableColumnFilter
        pageSize={pageSize || 100}
        rowCount={meta.count}
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selectedAssetIds}
        onSelectionModelChange={setSelectedAssetIds}
        rowsPerPageOptions={[25, 50, 100]}
        page={pageNumber}
        initialState={{
          columns: {
            columnVisibilityModel: {
              orderNumber: !fieldsToHide.includes("orderNumber"),
              scheduledDate: !fieldsToHide.includes("scheduledDate"),
              address: !fieldsToHide.includes("address"),
              state: !fieldsToHide.includes("state"),
              endOfLifeCondition: !fieldsToHide.includes("endOfLifeCondition"),
              quantity: !fieldsToHide.includes("quantity"),
              assetTag: !fieldsToHide.includes("assetTag"),
            },
          },
          pinnedColumns: { right: ["actions"] },
        }}
        onPageChange={(page) => {
          setPageNumber(page);
        }}
        onPageSizeChange={(n) => {
          setPageSize(n);
        }}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: CustomToolbar,
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No assets matching your filters
            </Stack>
          ),
        }}
        componentsProps={{ toolbar: { count: meta.count } }}
      />
      {isLoading && (
        <Box paddingTop={5}>
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
        </Box>
      )}
    </Box>
  );
}
