import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useAuth from "hooks/useAuthentication";
import { Menu } from "components/common/Menu";
import { useMediaQuery } from "@mui/material";
import theme from "lib/theme";
import { useRef } from "react";
import TokenService from "services/token.service";
import MenuIcon from "@mui/icons-material/Menu";
import logoImage from "../../assets/images/nerdit.png";

interface NavProps {
  open: boolean;
  handleToggle(): void;
}

export const navActions = {};

function Nav({ open, handleToggle }: NavProps) {
  const isTabletView = useMediaQuery(theme.breakpoints.down("lg"));
  const { loggedIn } = useAuth();

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: 0, zIndex: 10 }}>
      <AppBar
        variant="outlined"
        elevation={0}
        sx={{ bgcolor: "white", color: "#000" }}
      >
        <Toolbar>
          {isTabletView && loggedIn && (
            <IconButton
              color="primary"
              onClick={handleToggle}
              aria-label={open ? "Collapse Sidebar" : "Expand Sidebar"}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: "flex", alignItems: "center" }}
          ></Typography>
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            {window.location.host.includes("nerd") && (
              <img src={logoImage} alt="logo" style={{ height: "40px" }} />
            )}
          </Box>
          {loggedIn && (
            <Box sx={{ display: "flex", alignItems: "end" }}>
              <Menu
                button={(props) => (
                  <IconButton
                    aria-label="Profile"
                    size="large"
                    color="primary"
                    {...props}
                  >
                    <AccountCircleIcon />
                  </IconButton>
                )}
                actions={[
                  {
                    label: "Log Out",
                    onClick: () => {
                      TokenService.remove();
                      window.location.href = "/login";
                    },
                  },
                ]}
              ></Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Nav;
