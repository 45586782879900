import { Typography, Button, Box } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import theme from "lib/theme";
import usePickups from "hooks/pickups/usePickups";
import PickupCards from "./PickupCards";

function Pickups() {
  let showAlert = false;
  const {
    pickups,
    errorMessage,
    isLoading,
    getPickups,
    hasNextPage,
    deletePickup,
    incrementCurrentPage,
  } = usePickups();

  return (
    <div>
      <Box>
        <Breadcrumbs
          data={[{ label: "Home", to: "/" }, { label: "Pickups" }]}
        ></Breadcrumbs>
      </Box>

      <Box
        sx={[
          { float: "right", [theme.breakpoints.down("sm")]: { float: "none" } },
          !showAlert && {
            position: "relative",
            top: "-24px",
            [theme.breakpoints.down("sm")]: { position: "static", mt: 2 },
          },
        ]}
      >
        <span>
          <Button
            component={RouterLink}
            variant="contained"
            size="large"
            to="/pickups/new"
          >
            Create a Pickup
          </Button>
        </span>
      </Box>

      <Typography
        variant="h4"
        mt={{ xs: 3, sm: showAlert ? 0 : 3 }}
        fontWeight="bold"
      >
        Pickups
      </Typography>

      <PickupCards
        pickups={pickups}
        errorMessage={errorMessage}
        isLoading={isLoading}
        getPickups={getPickups}
        hasNextPage={hasNextPage}
        deletePickup={deletePickup}
        incrementCurrentPage={incrementCurrentPage}
      />
    </div>
  );
}

export default Pickups;
