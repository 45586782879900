import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { api } from "services/api.service";
import { OnboardingStage } from "types/onboarding";

export const OnboardingContext = createContext<{
  fetchOnboarding: () => void;
  isLoading: boolean;
  onboarding: OnboardingStage[];
}>({
  fetchOnboarding: () => {},
  isLoading: true,
  onboarding: [],
});

export const OnboardingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [onboarding, setOnboarding] = useState<OnboardingStage[]>([]);

  const fetchOnboarding = useCallback(() => {
    setIsLoading(true);
    api.get(`/onboarding`).then(({ data }) => {
      setOnboarding(data);
    });
  }, []);

  useEffect(() => {
    fetchOnboarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardingContext.Provider
      value={{ fetchOnboarding, isLoading, onboarding }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default function useOnboarding() {
  return useContext(OnboardingContext);
}
