import { Box, Typography, FormControl, Button, MenuItem } from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import { userRoles } from "constants/userRoles";
import { useFormik } from "formik";
import theme from "lib/theme";
import { useParams } from "react-router-dom";
import { NewUser, User } from "types/user";

type UserFormProps = {
  user: NewUser | User;
  onSubmit: (user: any) => any;
};

export default function UserForm({ user, onSubmit }: UserFormProps) {
  const params = useParams();

  const configuredUser = () => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };
  };

  const formik = useFormik({
    initialValues: configuredUser(),
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Box
          sx={{
            mt: 3,
            p: 4,
            background: "white",
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography
            variant="h6"
            fontWeight={"600"}
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
          >
            {params.uuid ? "Edit User" : "Add User"}
          </Typography>

          <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 3 }}>
            <Box
              sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}
            >
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="first-name">
                  First Name
                </InputLabel>
                <InputBase
                  id="first-name"
                  placeholder="First Name"
                  {...formik.getFieldProps("firstName")}
                />
              </FormControl>

              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="last-name">
                  Last Name
                </InputLabel>
                <InputBase
                  id="last-name"
                  placeholder="Last Name"
                  {...formik.getFieldProps("lastName")}
                />
              </FormControl>
            </Box>
            <Box
              sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}
            >
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="email">
                  Email
                </InputLabel>
                <InputBase
                  id="email"
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="phoneNumber">
                  Phone Number
                </InputLabel>
                <InputBase
                  id="phoneNumber"
                  placeholder="+1 123 456 7890"
                  {...formik.getFieldProps("phoneNumber")}
                />
              </FormControl>
            </Box>{" "}
          </Box>

          <Box textAlign="right" mt={3}>
            <Button variant="contained" type="submit">
              {params.uuid ? "save user" : "invite user"}
            </Button>
          </Box>
        </Box>
      </div>
    </form>
  );
}
