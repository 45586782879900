import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";

export default function useCreatePickup() {
  const navigate = useNavigate();

  const createPickup = useCallback(
    (pickupData: Pickup) =>
      api.post("/pickups", { pickup: pickupData }).then((res: any) => {
        navigate(`/pickups`);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    createPickup,
  };
}
