import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  Button,
  MenuItem,
  Select,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { useFormik } from "formik";
import theme from "lib/theme";
import { useParams } from "react-router-dom";
import { Pickup } from "types/pickup";
import AddIcon from "@mui/icons-material/Add";
import { useDropzone, DropEvent, FileRejection } from "react-dropzone";
import {
  IconFile,
  IconCsv as IconFileCsv,
  IconFileSpreadsheet,
} from "@tabler/icons-react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { api } from "services/api.service";
import { useCurrentUser } from "hooks/useCurrentUser";

interface PickupFormProps {
  pickup?: Pickup;
  onSubmit: (values: Pickup) => void;
  locations: { id: string; name: string; address: string }[];
}

interface FileWithPreview {
  name: string;
  preview: string;
  type: string;
  s3Url?: string;
  size?: number;
}

const PickupForm: React.FC<PickupFormProps> = ({
  pickup,
  onSubmit,
  locations,
}) => {
  const params = useParams();
  const [addNewLocation, setAddNewLocation] = useState(false);
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [uploading, setUploading] = useState(false);
  const { currentUser } = useCurrentUser();
  const [sameContact, setSameContact] = useState(false);

  useEffect(() => {
    if (pickup?.fileUrls) {
      const existingFiles = pickup.fileUrls.map((url) => {
        const fileName = url.split("/").pop();
        return {
          name: fileName,
          preview: url,
          type: url.includes(".csv")
            ? "text/csv"
            : url.includes(".xlsx") || url.includes(".xls")
            ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            : url.includes(".jpg") ||
              url.includes(".jpeg") ||
              url.includes(".png")
            ? "image/jpeg"
            : "application/octet-stream",
          s3Url: url,
          size: 1048576, // Placeholder size, 1MB
        } as FileWithPreview;
      });
      setFiles(existingFiles);
    }
  }, [pickup]);

  const initialValues = {
    id: 0,
    onsite_contact_name: pickup?.onsiteContactName || "",
    onsite_contact_phone: pickup?.onsiteContactPhone || "",
    onsite_contact_email: pickup?.onsiteContactEmail || "",
    scheduled_date: pickup?.scheduledDate || "",
    reason: pickup?.reason || "",
    contact_name: currentUser?.firstName + " " + currentUser?.lastName || "",
    contact_email: currentUser?.email || "",
    contact_phone: currentUser?.phoneNumber || "",
    comments: pickup?.comments || "",
    location_id: pickup?.location?.id || "",
    fileUrls: pickup?.fileUrls || [],
    location: addNewLocation
      ? {
          id: "0",
          name: pickup?.location?.name || "",
          address: pickup?.location?.address || "",
          address2: pickup?.location?.address2 || "",
          city: pickup?.location?.city || "",
          state: pickup?.location?.state || "",
          zipcode: pickup?.location?.zipcode || "",
          description: pickup?.location?.description || "",
        }
      : undefined,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setUploading(true);
      const updatedValues = { ...values };

      const uploadPromises = files.map(async (file) => {
        if (file.s3Url) {
          return file.s3Url;
        }

        const fileName = `${uuidv4()}-${file.name}`;
        try {
          const response = await api.post("/pickups_presigned_url", {
            key: fileName,
          });

          const presignedUrl = response.data.presignedUrl;

          if (!presignedUrl) {
            throw new Error("Failed to get presigned URL");
          }

          await axios.put(presignedUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          return `https://alpha-e.s3.amazonaws.com/public-images/pickup_files/${fileName}`;
        } catch (error) {
          console.error(
            "Error in getting presigned URL or uploading file:",
            error
          );
          throw error;
        }
      });

      try {
        const uploadedUrls = await Promise.all(uploadPromises);
        updatedValues.fileUrls = uploadedUrls;
        if (!addNewLocation && updatedValues.location) {
          delete updatedValues.location;
        }
        onSubmit(updatedValues);
      } catch (error) {
        console.error("File upload failed:", error);
      } finally {
        setUploading(false);
      }
    },
  });

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ) as FileWithPreview[];
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
    []
  );

  const removeFile = (file: FileWithPreview) => () => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const getFileIcon = (type: string) => {
    if (type.includes("csv")) return <IconFileCsv size={40} />;
    if (type.includes("spreadsheet") || type.includes("excel"))
      return <IconFileSpreadsheet size={40} />;
    if (type.includes("image")) return null;
    return <IconFile size={40} />;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {}, // Allow any file type
    maxSize: 10485760, // 10MB
  });

  const handleSameContactChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSameContact(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("onsite_contact_name", formik.values.contact_name);
      formik.setFieldValue("onsite_contact_phone", formik.values.contact_phone);
      formik.setFieldValue("onsite_contact_email", formik.values.contact_email);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          mt: 3,
          p: 4,
          background: "white",
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography
          variant="h6"
          fontWeight={"600"}
          sx={{ display: "flex", gap: 1, alignItems: "center" }}
        >
          {params.pickupUuid ? "Edit Pickup" : "New Pickup"}
        </Typography>

        {/* Location Selection */}
        {!addNewLocation ? (
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink htmlFor="location_id">
              Choose Location
            </InputLabel>
            <Select
              id="location_id"
              displayEmpty
              inputProps={{ "aria-label": "Choose Location" }}
              {...formik.getFieldProps("location_id")}
              onChange={(e) => {
                if (e.target.value === "add_new") {
                  setAddNewLocation(true);
                } else {
                  formik.handleChange(e);
                }
              }}
              sx={{ border: "1px solid", borderRadius: 1, padding: "8px" }}
            >
              <MenuItem value="" disabled>
                Choose Location
              </MenuItem>
              {locations.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name} - {location.address}
                </MenuItem>
              ))}
              <MenuItem
                value="add_new"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AddIcon sx={{ marginRight: "8px" }} />
                Add New Location
              </MenuItem>
            </Select>
          </FormControl>
        ) : (
          <>
            {/* Location Details Section */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                Location Details
              </Typography>
              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                <InputLabel shrink htmlFor="location.name">
                  Nickname
                </InputLabel>
                <InputBase
                  id="location.name"
                  placeholder="HQ"
                  {...formik.getFieldProps("location.name")}
                />
              </FormControl>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                  gap: 3,
                  mb: 2,
                }}
              >
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="location.address">
                    Address
                  </InputLabel>
                  <InputBase
                    id="location.address"
                    placeholder="Address"
                    {...formik.getFieldProps("location.address")}
                  />
                </FormControl>

                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="location.address2">
                    Address 2
                  </InputLabel>
                  <InputBase
                    id="location.address2"
                    placeholder="Address 2"
                    {...formik.getFieldProps("location.address2")}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
                  gap: 3,
                  mb: 2,
                }}
              >
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="location.city">
                    City
                  </InputLabel>
                  <InputBase
                    id="location.city"
                    placeholder="City"
                    {...formik.getFieldProps("location.city")}
                  />
                </FormControl>

                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="location.state">
                    State
                  </InputLabel>
                  <InputBase
                    id="location.state"
                    placeholder="State"
                    {...formik.getFieldProps("location.state")}
                  />
                </FormControl>

                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="location.zipcode">
                    Zipcode
                  </InputLabel>
                  <InputBase
                    id="location.zipcode"
                    placeholder="Zipcode"
                    {...formik.getFieldProps("location.zipcode")}
                  />
                </FormControl>
              </Box>
              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                <InputLabel shrink htmlFor="location.description">
                  Building Details
                </InputLabel>
                <InputBase
                  id="location.description"
                  placeholder="Does your building have a loading doc? Where should we park? Does it have an elevator? Does it require proof of insurance? Do our drivers need to be registered with the front desk?"
                  multiline
                  rows={3}
                  {...formik.getFieldProps("location.description")}
                />
              </FormControl>
            </Box>
            <Box textAlign="right">
              <Button
                variant="outlined"
                onClick={() => setAddNewLocation(false)}
              >
                Cancel
              </Button>
            </Box>
          </>
        )}

        <Divider sx={{ my: 4 }} />

        {/* Scheduling Point of Contact Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle2" fontWeight="600" gutterBottom>
            Scheduling point of contact
          </Typography>
          <Typography variant="body2" gutterBottom>
            This is the person responsible for deciding to do the pickup and who
            we should reach out to about any logistics or business details.
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 3,
              mb: 2,
            }}
          >
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="contact_name">
                Contact Name
              </InputLabel>
              <InputBase
                id="contact_name"
                placeholder="Contact Name"
                {...formik.getFieldProps("contact_name")}
              />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="contact_email">
                Contact Email
              </InputLabel>
              <InputBase
                id="contact_email"
                type="email"
                placeholder="Contact Email"
                {...formik.getFieldProps("contact_email")}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 3,
            }}
          >
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="contact_phone">
                Contact Phone
              </InputLabel>
              <InputBase
                id="contact_phone"
                placeholder="Contact Phone"
                {...formik.getFieldProps("contact_phone")}
              />
            </FormControl>
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        {/* Onsite Point of Contact Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle2" fontWeight="600" gutterBottom>
            Onsite point of contact
          </Typography>
          <Typography variant="body2" gutterBottom>
            This is the person we should call the day of the pickup to access
            the building and coordinate picking up the electronics.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={sameContact}
                onChange={handleSameContactChange}
                name="sameContact"
                color="primary"
              />
            }
            label="Onsite contact is the same as Scheduling contact"
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 3,
              mb: 2,
            }}
          >
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="onsite_contact_name">
                Onsite Contact Name
              </InputLabel>
              <InputBase
                id="onsite_contact_name"
                placeholder="Onsite Contact Name"
                {...formik.getFieldProps("onsite_contact_name")}
              />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="onsite_contact_phone">
                Onsite Contact Phone
              </InputLabel>
              <InputBase
                id="onsite_contact_phone"
                placeholder="Onsite Contact Phone"
                {...formik.getFieldProps("onsite_contact_phone")}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 3,
            }}
          >
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="onsite_contact_email">
                Onsite Contact Email
              </InputLabel>
              <InputBase
                id="onsite_contact_email"
                type="email"
                placeholder="Onsite Contact Email"
                {...formik.getFieldProps("onsite_contact_email")}
              />
            </FormControl>
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        {/* Pickup Details Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1" fontWeight="600" gutterBottom>
            Pickup Details
          </Typography>

          {/* File Upload Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="subtitle1" fontWeight="600" gutterBottom>
              Asset list, photos of equipment, or any other helpful files
            </Typography>
            <Typography variant="body2" gutterBottom>
              Any information you can give us on the specific items we are
              picking up makes it easier on our team to assure we have the right
              size truck(s) and team size.
            </Typography>
            <Box
              {...getRootProps({ className: "dropzone" })}
              sx={{
                p: 4,
                border: "2px dashed",
                borderColor: theme.palette.primary.main,
                borderRadius: 1,
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1">
                Drag & drop files here, or click to select files
              </Typography>
              <Typography variant="body2">(Maximum file size: 10MB)</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              {files.map((file, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", mt: 1 }}
                >
                  {file.type.includes("image") ? (
                    <img
                      src={file.preview}
                      alt={file.name}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#f0f0f0",
                        marginRight: "10px",
                      }}
                    >
                      {getFileIcon(file.type)}
                    </Box>
                  )}
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body2">{file.name}</Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={removeFile(file)}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>

          <Divider sx={{ my: 4 }} />

          <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
            <InputLabel shrink htmlFor="comments">
              Comments
            </InputLabel>
            <InputBase
              id="comments"
              placeholder="What items do you expect in the pickup? How many desktops, laptops, tvs etc?"
              multiline
              rows={3}
              {...formik.getFieldProps("comments")}
            />
          </FormControl>
        </Box>

        <Divider sx={{ my: 4 }} />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
            gap: 3,
            mb: 2,
          }}
        >
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor="scheduled_date">
              Preferred Date
            </InputLabel>
            <InputBase
              id="scheduled_date"
              type="date"
              {...formik.getFieldProps("scheduled_date")}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Box>

        <Box textAlign="right" mt={3}>
          <Button variant="contained" type="submit" disabled={uploading}>
            {params.pickupUuid ? "Save Pickup" : "Request Pickup"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default PickupForm;
