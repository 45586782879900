import { Box, Typography, Button } from "@mui/material";
import { Computer } from "@mui/icons-material";
import theme from "lib/theme";
import { useState } from "react";
import { Modal } from "components/common/Modal";

interface SecurityLocksProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bulkUnenroll: () => void;
}

export function SecurityLocks({
  open,
  setOpen,
  bulkUnenroll,
}: SecurityLocksProps) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <SecurityLocksBody bulkUnenroll={bulkUnenroll} setOpen={setOpen} />
    </Modal>
  );
}

function SecurityLocksBody({
  bulkUnenroll,
  setOpen,
}: {
  bulkUnenroll: () => void;
  setOpen: any;
}) {
  let [step, setStep] = useState(0);

  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          minHeight: "500px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {step === 0 && (
          <Box>
            <Computer color="primary" sx={{ fontSize: "60px" }} />
            <Typography mt={1} variant="h5" component="h2" fontWeight={800}>
              Security Locks
            </Typography>
            <Typography mt={0.5} color="text.secondary">
              In order for us to repurpose your assets, we need to ensure the
              assets are completely released when they reach us. Here are some
              different types of locks you might come across that we need your
              help with!
            </Typography>
            <Box textAlign="left" sx={{ color: "text.secondary" }}>
              <ul style={{ paddingLeft: "16px" }}>
                <li>
                  <strong>Bios Lock (PCs):</strong> Remove via password.
                </li>
                <li>
                  <strong>Enrolled (Apple & PCs):</strong> Remove via serial
                  number in Apple Business Manager (sometimes managed by
                  additional MDM software).
                </li>
                <li>
                  <strong>EFI (Apple up to 2017):</strong> Remove via password.
                </li>
                <li>
                  <strong>Activation Lock (Apple):</strong> Remove via bypass
                  code on the Activation Lock screen in the Setup Assistant in
                  your MDM. Can be removed via request to Apple as well.
                </li>
              </ul>
            </Box>
          </Box>
        )}
        {step === 1 && (
          <Box>
            <Computer color="primary" sx={{ fontSize: "60px" }} />
            <Typography mt={1} variant="h5" component="h2" fontWeight={800}>
              Confirm that the selected assets are released?
            </Typography>
            <Typography mt={0.5} color="text.secondary">
              Please confirm that all of your selected assets are completely
              released from any device management programs. We’ll perform a
              check in our internal systems and get back to you if any issues
              arise.
            </Typography>
          </Box>
        )}

        <Box
          mt={"auto"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {step === 0 && (
            <Button variant="contained" onClick={() => setStep(1)}>
              Next
            </Button>
          )}
          {step === 1 && (
            <Button
              variant="contained"
              onClick={() => {
                bulkUnenroll();
                setOpen(false);
              }}
            >
              release assets
            </Button>
          )}
          <Box
            sx={[
              {
                display: "flex",
                gap: 1,
                button: {
                  width: "12px",
                  height: "12px",
                  backgroundColor: theme.palette.action.disabled,
                  borderRadius: "50%",
                  border: 0,
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                },
              },
            ]}
          >
            {Array.from({ length: 2 }, (_, i) => {
              let isActive = i === step;
              return (
                <button
                  key={i}
                  style={
                    isActive
                      ? { backgroundColor: theme.palette.primary.main }
                      : undefined
                  }
                  onClick={() => setStep(i)}
                ></button>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
